<template>
    <div v-if="siteProfile" class="d-flex text-center flex-column flex-md-row flex-grow-1">
        <v-sheet class="layout-side mx-auto mx-md-1 d-none d-md-flex flex-md-column justify-space-between px-2">
            <div class="mt-3 mt-md-10 pa-2">
                <div class="display-2 font-weight-bold primary--text">
                    {{ siteProfile.name }}
                </div>
                <div class="title my-2">
                    {{ $t('views.layouts.passport.slogan') }}
                </div>
            </div>
            <img class="w-full" src="/images/illustrations/signin-illustration.svg" />
        </v-sheet>

        <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
            <div class="layout-content ma-auto w-full">
                <!-- 根据应用程序组件调整内容大小 -->
                <v-main>
                    <!-- 为应用程序提供正确的插槽 -->
                    <router-view />
                </v-main>
            </div>
            <div class="overline mt-4">
                <!-- {{ product.name }} - {{ product.version }} -->
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store';

export default {
    data () {
        return {
            siteProfile: null
        };
    },
    async created () {
        this.siteProfile = await store.dispatch('app/getSiteProfile');
        this.setDocumentTitle(this.siteProfile.name);
        this.setTheme(this.siteProfile.theme);
    }
};
</script>

<style scoped>
.layout-side {
    width: 420px;
}

.layout-content {
    max-width: 480px;
}
</style>
